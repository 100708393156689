.footer{
    height: 550px;
    width: 100%;
    background-color: rgb(38, 42, 75);
    color: white;

}
.icon-box img{
    height: 25px;
    width: 25px;
    cursor: pointer;
}

.main-footer{
    display: flex;
   
    
    
}

.column-1,.column-2,.column-3,.column-4{
    margin-top: 100px;
    line-height: 1.3;
    margin-left: 80px;
}

.column-1 h1{
color: white;
font-size: 37px;
cursor: pointer;
}
.column-1 p{
    line-height: 1.8;
    color: rgb(133, 133, 133);
    cursor: pointer;
}

.icon-container{
    display: flex;

}

.icon-box{
    margin: 5px;
    padding: 8px 8px 5px 8px;
    border: 2px solid rgb(133, 133, 133);
    border-radius: 14px;
    cursor: pointer;
}
.column-2 h3,.column-3 h3,.column-4 h3{
    color: white;
    font-size: 26px;
    cursor: pointer;
}
.column-2 p,.column-3 p,.column-4 p{
    color: rgb(133, 133, 133);
    transition: 0.5s;
    cursor: pointer;
    
}
.column-4 li{
    margin-left: -80px;
}

.column-4 li a:hover{
    font-size: 17px;
    transition: 0.5s;
    color: rgb(175, 175, 175);
}

.footer-hr{
    margin-top: 30px;
    background-color: rgb(70, 70, 70);
    width: 95%;
    margin-left: 30px;
    
}

.copyright-line{
    display: flex;
    align-items: center;
    margin-left: 30px;

}

.footer-link{
    color: rgb(3, 159, 207);
    margin-left: 6px;
}

.copyright-line p{
    margin-left: 2px;
    color: rgb(207, 207, 207);
    cursor: pointer;
}
.copyright-line img{
    height: 20px;
    width: 20px;
}

@media (max-width: 600px){
    .main-footer{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-right: 60px;
        
    }
    .footer{
        height: 100%;
        width: 100%;
        
    }
}