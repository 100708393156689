.first-block{
    background: url(../assets/first-block-all-pages.jpg);
    height: 50vh;
    color: white;
    background-size: cover;
    background-repeat: no-repeat;
    margin-bottom: -140px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 20px;
    animation: fadeIn 2s;
}
@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }

.page-name{
    font-size: 50px;
    margin-left: 100px;
}


li{
    padding: 10px 100px;
    list-style: none;
}
@media (max-width: 600px) {
    .first-block{
        height: 80vh;
        margin-bottom: 40px;
    }
}