/* Navbar.css */

.nav {
    display: flex;
    position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  
    justify-content: space-around;
    align-items: center;
    padding: 0 20px;
    background-color: white;
    color: #504f4f;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    box-shadow: 1px 1px 20px rgb(121, 121, 121);
    
}

.logo img {
    height: 60px; 
    width: 180px;
}
.logo {
    display: flex;
    align-items: center;
}
.logo h1{
    margin-left: 25px;
    font-weight: 800;
    color: black;
}

.menu {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    gap: 2px;
}

.menu li {
    cursor: pointer;
    position: relative;
    padding: 12px 14px;
    font-weight: bold;
    color: #504f4f;
}



.menu li hr {
    position: absolute;
    bottom: 0;
    left: 2;
   border-radius: 22px;
    width: 40%;
    height: 3px;
    background-color: rgb(9, 61, 138);
    border: none;
    margin: 0;
}
.menu li:active{
    color: rgb(0, 119, 255);
    
}


@media (max-width: 600px) {
    .nav {
    display: flex;
    justify-content: space-between;
    align-items: start;
        
    }
    .logo{
        width: 100px;
    }
    
    .menu {
        flex-direction: column;
        display: none;
        align-items: center;
        width: 100%;
        margin-top: 90px;
        margin-right: 40px;
    }

    .menu.active {
        display: flex;
    }

    .menu li {
        padding: 15px;
        text-align: center;
        width: 100%;
    }
    .menu li:hover {
        color: rgb(9, 61, 138);
    }
    .menu li hr{
        display: none;
    }
   
}

/* Hamburger Menu Styles (optional) */
.hamburger {
    display: none;
    cursor: pointer;
    
}

.hamburger div {
    width: 25px;
    height: 2px;
    background-color: black;
    margin: 5px 0;
}

/* Show hamburger icon on mobile */
@media (max-width: 600px) {
    .hamburger {
        display: block;
        align-items: center;
        justify-content: center;
        padding: 10px;
        border: 2px solid rgb(51, 51, 51);
        border-radius: 10px;

    }
}


