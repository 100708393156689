.main{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 35px;
    background-color: rgb(47, 54, 71);
    color: rgb(141, 140, 140);
    
}

.left img{
    height: 20px;
    width: 20px;
    padding: 10px;
    cursor: pointer;
}
.right img{
    height: 20px;
    width: 20px;
    padding: 10px;
    cursor: pointer;
}

.left{
    margin-left: 20px;
    display: flex;
    justify-content: center;
    align-items: center;

}

.right{
    margin-right: 50px;
    
}
@media (max-width: 768px) {
    .main {
        display: none;
      

    }
}