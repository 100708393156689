.service-paragraph{
    height: 55vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 200px;
   font-size: 18px;
   margin-bottom: 100px;
}
.service-paragraph p{
    color: gray;
}
.services-cards{
    display: flex;
    flex-direction: column;
    align-items: center;
}