.hero-main{
    display: flex;
    height: 100vh;
    justify-content: space-around;  
    margin-top: 250px;
    animation: fadeIn 3s;
}
@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
.call-para{
    font-weight: bold;
    color: black;
    font-size: 22px;
    margin-left: 15px;
    line-height: 1.1;
}


.hero-left{
    background: url(../assets/hero.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 0 0 8px 0;
    width: 45%;
    height: 70%;
   margin-top: 80px;
   margin-left: 150px;
   
}

.year-box{
    height: 170px;
    width: 210px;
    border: 12px solid white;
    border-radius: 16px;
    background-color: rgb(9, 61, 138);
    color: white;
    text-align: center;
    margin-top: 35px;
    margin-left: -550px;
    line-height: 1.5;
    
}

.year-box p{
    font-size: 25px;
    font-weight: 900;
}

.hero-right{
    width: 60%;
  margin-top: 25px;
  margin-left: 420px;
}
.hero-right h1{
    color: rgb(54, 54, 54);
    font-weight: 800;
    font-size: 37px;
    line-height: 1.3;
    letter-spacing: 1.7;
}
.para{
    color: rgb(9, 61, 138);
    font-weight: 200;
    font-size: 19px;
    line-height: 1.8;
    letter-spacing: 1.7;
    
}
.box{
    display: flex;
}
.billing{
    display: flex;
    align-items: center;
}
.billing p{
    font-weight: bold;
    color: black;
    font-size: 22px;
    margin-left: 15px;
}
.business{
    display: flex;
    align-items: center;
    margin-left: 60px;
}
.business p{
    font-weight: bold;
    color: black;
    font-size: 22px;
    margin-left: 15px;
    line-height: 1.1;
}

.billing-box{
    padding: 7px;
    border: 2px solid rgb(9, 61, 138);
    border-radius: 19px;
}
.business-box{
    padding: 7px;
    border: 2px solid rgb(9, 61, 138);
    border-radius: 19px;
}

.billing-box img{
    height: 40px;
    width: 40px;
}
.business-box img{
    height: 40px;
    width: 40px;
}
hr{
    width: 90%;
    margin-left: 1px;
}
@media (max-width: 600px) {
    .hero-main{
        display: flex;
        height: 290vh;
        width: 100%;
        flex-direction: column;
        align-items: center;
        margin-top: -240px;
        margin-left: -10px;
        

       
        
    }
    .hero-left{
        margin-left: 10px;
        width: 80%;
        height: 18%;
    }

    .year-box{
       display: none;
        /* margin-right: 400px; */
    }
    .hero-right{
        margin-top: -300px;
        margin-left: 2px;
        display: flex;
        flex-direction: column;
        align-items: center;
        
        
    }
    .box{
        display: flex;
        flex-direction: column;
        margin-left: -30px;
    }
    .business{
        margin-left: 2px;
    }
   
    
    
}
