.contact-box{
    margin-top: 200px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 100px;
}
.contact-left{
    width: 40%;
    padding-left: 140px;
}
.contact-left h1{
    font-size: 38px;
}
.contact-left h3{
    padding-top: 25px;
}
.address{
    display: flex;
    align-items: center;
}
.address img{
    height: 30px;
    width: 30px;
}
.address p{
    
    padding-left: 12px;
}


.contact-right iframe{
    margin-top: 30px;
    border-radius: 10px;
    box-shadow: 0 0 20px rgb(179, 179, 179);
}
@media (max-width: 600px) {
    .contact-box{
        margin-top: -20px;
    }
 .contact-left{
    margin-left: -100px;
 }   
 .contact-right iframe{
    width: 300px;
    height: 320px;
    margin-left: 30px;
 }
}



