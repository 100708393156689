.paragraph{
    height: 55vh;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: white;
    background-color:  rgb(9, 61, 138);
    font-size: 24px;
}

.benefit{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 100px;
    margin-bottom: 30px;

}
.benefit h1{
    font-size: 38px;
}
.b-heading{
   padding-left: 40px;
   padding-top: 50px;
}
.b{
    padding-left: 12px;
    
}
@media (max-width: 600px) {
    .paragraph{
        height: 80vh;
    }
}