.prices-cards{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 200px;
    margin-bottom: 30px;

}

.prices-cards h1{
    font-size: 38px;
}