.faqs-block{
    margin-top: 200px;
    margin-left: 50px;
}

.faqs-block h1{
    font-size: 40px;
}

.question-box{
margin-top: 70px;
margin-bottom: 70px;
}

.question{
    padding-top: 25px;
}
.question h4{
    font-size: 17px;
    color: rgb(9, 61, 138);
}