
.slider {
    position: relative;
    width: 100%; 
    height: 90vh;
    margin: auto;

  }
  
  .slider-image {
    width: 100%;
    display: block;
  }
  
  .nav-button {
    position: absolute;
    top: 50%;
    left: 96%;
    right: 96%;
    transform: translateY(-50%);
    background-color: rgb(9, 61, 138);
    color: white;
    border: none;
    border-radius: 50px;
    padding: 10px;
    cursor: pointer;
    z-index: 100;
  }
  
  .prev {
    left: 15px;
  }
  
  .next {
    right: 15px;
  }

  @media (max-width: 600px){
    .slider-image{
      height: 60vh;
      margin-bottom: -250px;
    }
    .nav-button{
      position: absolute;
      top: 30%;
      left: 92%;
      right: 92%;
      transform: translateY(-50%);
      z-index: 100;
    }
  }


  /* about */
.about-block{
    height: 100vh;
    display: flex;
    flex-wrap: wrap;
   
}  
 

.about-left{
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;

}


.block{
    display: grid;
    grid-template-columns: auto auto;
    gap: 10px;
    margin-top: 30px;
    margin-left: 120px;
}
.about-left h1{
  font-size: 38px;
}


.about-right{
    width: 50%;
    
    
}


.image-box{
  padding: 7px;
    border: 2px solid rgb(9, 61, 138);
    border-radius: 19px;
    width: 40px;
    height: 40px;
    box-shadow: 0 0 4px rgb(9, 61, 138);
}

.image-box img{
  height: 40px;
  width: 40px;
  
}
.about-box{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  height: 150px;
  width: 200px;
  text-align: center;
  font-weight: 600;
  background-color: white;
  border: 14px solid rgb(225, 253, 255);
  box-shadow: inset 0 0 10px rgb(9, 61, 138),0 0 10px rgb(9, 61, 138);
  border-radius: 25px;
  
}
.about-right img{
  height: 550px;
  width: 550px;
  border-radius: 10px;
  margin-left: 40px;
  box-shadow: 0 0 8px rgb(9, 61, 138);
}

.about-us-btn{
  display: flex;
  justify-content: center;

  padding: 16px 0 16px 0;
  background-color: rgb(9, 61, 138);
  color: white;
  border: 2px solid white;
  border-radius: 8px;
  transition: 0.5s;
  cursor: pointer;
}
.about-us-btn:hover{
  transition: 0.5s;
  background-color: rgb(0, 162, 255);
}
.about-us-btn li{
  list-style: none;
  color: white;
  font-weight: 600;
  font-size: 16px;
}
.about-us-btn li a{
  list-style: none;
  color: white;
  font-weight: 600;
  font-size: 16px;
}
.btn{
  display: flex;
  justify-content: center;
  margin-top: -40px;
}
@media (max-width: 600px){
  .about-block{
    display: flex;
    flex-direction: column;
    height: 245vh;
    margin-left: 40px;
    margin-top: -70px;

  }

  .block{
    display: grid;
    grid-template-columns: auto;
    justify-content: center;
    align-items: center;
  }
  .about-right{
    margin-left: -50px;
    margin-top: 40px;
    
  }
  .about-right img{
    width: 300px;
  }
  
}


/* more-services */
.more-services{
  height: 200vh;
  padding: 100px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

}
.heading h1{
  font-size: 38px;
}
.cards{
  display: flex;
  padding: 30px 0;
  gap: 60px;
  flex-wrap: wrap;

}

.card{
  
  text-align: left;
  height: 360px;
  width: 350px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgb(192, 192, 192);

}
.card p{
  margin-left: 30px;
  margin-top: 25px;
  line-height: 1.3;
  color: gray;
}
.services-heading-div{
  display: flex;
  align-items: center;
  margin-top: 31px;
  gap: 20px;

}
.servic-icon-div{
  height: 77px;
  width: 90px;
  border-radius: 0 10px 10px 0;
  background-color: rgb(9, 61, 138);
  display: flex;
  justify-content: center;
  align-items: center;
}
.service-icon{
  padding: 7px;
  border: 2px solid white;
  border-radius: 19px;
  width: 40px;
  height: 40px;
}
.service-icon img{
  height: 40px;
  width: 40px;
}

.service-btn{
  display: flex;
  justify-content: center;
  margin-top: 40px;
 
}

@media (max-width: 600px) {
  .more-services{
    height: 500vh;
  }
  .card{
    width: 300px;
    margin-left: 20px;
  }
}


/* prices */

.prices-block{
  height: 115vh;
  display: flex;
  flex-wrap: wrap;
  background-color: rgb(9, 61, 138);
  margin-top: -120px;
  
} 
.price-right{
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
}
.price-right h1{
  font-size: 38px;
  color: white;
}
.price-left{
  width: 50%; 
  
}
.price-left img{
  height: 450px;
  width: 500px;
  border-radius: 10px;
  margin-left: 80px;
  margin-top: 50px;
}
.price-block{
  display: grid;
  grid-template-columns: auto auto;
  gap: 10px;
  margin-top: 30px;
  margin-top: 10px;
}
.price-box{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  height: 140px;
  width: 200px;
  text-align: center;
  font-weight: 600;
  background-color: white;
  
  border-radius: 15px;
  
}
.prices-btn{
  display: flex;
  justify-content: center;
  margin-top: -110px;
  padding: 20px;
}
@media (max-width: 600px) {
  .prices-block{
    height: 210vh;
    display: flex;
    flex-direction: column;
    margin-top: -800px;
    
  }
  .price-left{
    margin-left: -50px;
  }
  .price-left img{
    width: 300px;
  }
  .price-right{
    margin-left: 85px;
    margin-top: 50px;
  }
  .price-block{
    display: grid;
    grid-template-columns: auto;
    
  }
}


